import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Context} from '../AppContext';
import InvoiceForm from './InvoiceForm';
import {
  FormExpander,
  Row,
  Label,
  Input,
  Hint,
  Button,
  Select,
  SelectOption,
  Text,
} from '../Widgets';
import {message, Alert} from 'antd';
import {ErrProfileFormat, ErrInvoiceFormat, ErrCheckoutForm} from '../errors';
import formatValidator from '../Utils/formatValidator';
import invoiceValidator from '../Utils/invoice-validator';
import {isEnt, isMonthly} from '../Utils/UserUtil';
import {navigate} from 'gatsby';
const appConfig = require('../data.json');

const {isNotEmpty, isEmail, isMobileNumber} = formatValidator;

function errorHandler(err) {
  console.warn(err);
  if (
    err instanceof ErrProfileFormat ||
    err instanceof ErrInvoiceFormat ||
    err instanceof ErrCheckoutForm
  ) {
    message.error(err.message);
  } else {
    message.error('成立訂單失敗');
  }
}

export default function DepositCreditForm(props) {
  const app = useContext(Context);
  const {profile, cart} = app.state;
  const [config, setConfig] = useState();
  const [userInvalid, setUserInvalid] = useState(false);
  const [agree, setAgree] = useState(false);
  const [validate, setValidate] = useState(false);
  const [credits, setCredits] = useState(3000);
  const [customizeCredits, setCustomizeCredits] = useState(0);
  const [feedback, setFeedback] = useState({
    limit: null,
    value: null,
    expire_date: null,
  });

  const is_ent = isEnt(profile) || isMonthly(profile);

  const verifyUser = useCallback((config) => {
    let {userConfig} = config;
    let {name, phone, email} = userConfig;
    if (!isNotEmpty(name) || !isNotEmpty(email) || !isNotEmpty(phone)) {
      throw new ErrProfileFormat('會員資料名稱、信箱、手機為必填');
    }
    if (!isEmail(email)) {
      throw new ErrInvoiceFormat('會員資料電子信箱格式錯誤');
    }
    if (!isMobileNumber(phone)) {
      throw new ErrProfileFormat('會員資料手機格式錯誤');
    }
  }, []);

  useEffect(() => {
    try {
      verifyUser(config);
      setUserInvalid(false);
    } catch (err) {
      setUserInvalid(true);
    }
  }, [config, verifyUser]);

  useEffect(() => {
    let userConfig = {
      name: '',
      phone: '',
      email: '',
      zip: '',
      tel: '',
      tel_ext: '',
      address: '',
    };
    let invoiceConfig = {
      invoice_type: 'two_copies',
      invoice_subtype: 'ezpay_vehicle',
      mobile_vehicle_code: '',
      citizen_personal_certificate_code: '',
      company_title: '',
      gui_number: '',
      donate_foundation_gui: '',
      love_code: '',
    };

    if (cart) {
      const _config = {...cart.config};

      //user
      userConfig = {
        name: _config.name || userConfig.name,
        phone: _config.phone || userConfig.phone,
        email: _config.email || userConfig.email,
        tel: _config.tel || userConfig.tel,
        tel_ext: _config.tel_ext || userConfig.tel_ext,
        zip: _config.zip || userConfig.zip,
        address: _config.address || userConfig.address,
      };

      //invoice
      invoiceConfig = {
        ...invoiceConfig,
        ..._config.invoiceConfig,
      };
    }

    if (profile) {
      userConfig.name = userConfig.name || profile.name;
      userConfig.phone = userConfig.phone || profile.phone;
      userConfig.email = userConfig.email || profile.email;
      userConfig.address = userConfig.address || profile.address;
    }

    setConfig({
      userConfig,
      invoiceConfig,
    });
  }, [profile, cart]);

  // 取得可獲得優惠規則
  useEffect(() => {
    (async () => {
      let resp;

      try {
        if (isEnt(profile)) {
          resp = await app.actions.getPromotionFeedback({
            user_type: 'ent_vip',
          });
        } else {
          resp = await app.actions.getPromotionFeedback({
            user_type: 'vip',
          });
        }
        setFeedback(resp);
      } catch (ex) {
        console.warn(ex);
      }
    })();
  }, [profile, app.actions]);

  const submit = async () => {
    try {
      let value = credits;
      if (value === 0) {
        if (customizeCredits < 100) {
          throw new ErrCheckoutForm('自訂點數不得小於100');
        }
        value = customizeCredits;
      }

      if (!agree) {
        throw new ErrCheckoutForm('請閱讀且同意注意事項');
      }

      verifyUser(config);

      setValidate(true);
      invoiceValidator(config.invoiceConfig);

      app.actions.setLoading(true);
      await app.actions.editConfig({
        ...config.userConfig,
        invoiceConfig: config.invoiceConfig,
      });
      let order = await app.actions.createCreditsOrder(value);
      navigate(`/order?id=${order.id}`);
    } catch (err) {
      errorHandler(err);
    }

    app.actions.setLoading(false);
  };

  // 計算加值後點數
  const estimate = ({credits, customizeCredits, feedback, profile}) => {
    let _credits = credits || customizeCredits;

    let result = {
      finalBuyCredit: 0, //加值點數
      feekbackCredit: 0, //贈送點數
      totalCredit: 0, /// 加值後帳戶總點數
    };

    result['finalBuyCredit'] = Number(_credits).toFixed();

    if (_credits >= 3000) {
      // 最低買 3000才有回饋
      result['feekbackCredit'] =
        _credits * feedback.value > feedback.limit
          ? feedback.limit.toFixed()
          : (_credits * feedback.value).toFixed();
    }

    result['totalCredit'] = (
      Number(profile.credits) +
      Number(result.feekbackCredit) +
      Number(result.finalBuyCredit)
    ).toFixed();

    return result;
  };

  let {finalBuyCredit, feekbackCredit, totalCredit} = estimate({
    credits,
    customizeCredits,
    feedback,
    profile,
  });

  return (
    <div>
      {userInvalid && (
        <Alert
          message="為保證發票資料正確，請至個人資料管理 > 基本資料，填入正確的會員資料，才能儲值點數。"
          type="warning"
          style={{marginBottom: 10}}
        />
      )}
      <FormExpander title="選擇加值面額" closable={false}>
        <Row>
          <Label flex="0 0 auto">帳戶剩餘點數：</Label>
          <Text size="sm" color={appConfig.colors.main}>
            {profile ? profile.credits : '-'}點
          </Text>
        </Row>

        <Row>
          <Label flex="0 0 auto">加值點數費用</Label>
          <Select value={credits} onChange={(value) => setCredits(value)}>
            <SelectOption value={3000}>3000元</SelectOption>
            <SelectOption value={5000}>5000元</SelectOption>
            <SelectOption value={10000}>10000元</SelectOption>
            <SelectOption value={20000}>20000元</SelectOption>
            <SelectOption value={30000}>30000元</SelectOption>
            <SelectOption value={50000}>50000元</SelectOption>
            <SelectOption value={100000}>100000元</SelectOption>
            <SelectOption value={0}>自訂點數</SelectOption>
          </Select>
        </Row>

        {credits === 0 && (
          <Row>
            <Label flex="0 0 auto">自訂點數</Label>
            <Input
              type="number"
              placeholder="需大於100"
              value={customizeCredits}
              onChange={(e) => setCustomizeCredits(e.target.value)}
            />
          </Row>
        )}

        <Row>
          <Label flex="0 0 auto">加值點數：</Label>
          <Text size="sm">{finalBuyCredit}點</Text>
        </Row>

        {/* 點數贈送優惠 */}
        {!(customizeCredits !== 0 && customizeCredits < 3000) && (
          <>
            <Row>
              <Label flex="0 0 auto">
                預儲優惠{isEnt(profile) ? '(企業預繳)' : '(預繳)'}：
              </Label>
              <Text size="sm" color={appConfig.colors.main}>
                點數儲值即贈送 {feedback.value * 100} %點數回饋，最多可回饋
                {feedback.limit}點
              </Text>
            </Row>
            <Row>
              <Label flex="0 0 auto">贈送點數：</Label>
              <Text size="sm">{feekbackCredit}點</Text>
            </Row>
          </>
        )}

        <Row>
          <Label flex="0 0 auto">加值後帳戶總點數：</Label>
          <Text size="sm" color={appConfig.colors.main}>
            {totalCredit}點
          </Text>
        </Row>
      </FormExpander>

      <FormExpander title="發票資料" closable={false}>
        {config && config.invoiceConfig && (
          <InvoiceForm
            validate={validate}
            setAgree={(value) => setAgree(value)}
            config={config.invoiceConfig}
            setConfig={(value) =>
              setConfig((prev) => ({
                ...prev,
                invoiceConfig: value,
              }))
            }
          />
        )}
      </FormExpander>

      <Row margin="0 0 30px 0">
        <div style={{flex: 1}} />
        <Button onClick={submit} disabled={userInvalid}>
          確認加值點數成立訂單
        </Button>
      </Row>
    </div>
  );
}
